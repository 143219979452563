/*
 * @Author: zt zhoutao@ydmob.com
 * @Date: 2024-04-16 15:20:56
 * @LastEditors: zt zhoutao@ydmob.com
 * @LastEditTime: 2024-04-22 14:26:45
 * @FilePath: /apiForManage/originLity.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import request from '@/utils/request'
// 素材快照列表
export function creativeCZReport (data, cancelToken) {
  return request({
    url: `/creativeSS/report`,
    method: 'post',
    data,
    cancelToken
  })
}
