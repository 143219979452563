export const dimesionEnum = {
  date: {
    name: '日期',
    prop: 'date',
    width: 120,
    value: 'date'
  },
  creativeUrl: {
    name: '素材内容',
    prop: 'cid',
    width: 220,
    value: 'cid'
  },
  publisherName: {
    name: '供应商',
    prop: 'publisherName',
    width: 150,
    value: 'publisher_id'
  },
  placeName: {
    name: '广告位',
    prop: 'placeName',
    width: 150,
    value: 'place_id'
  },
  sourceName: {
    name: '广告源',
    prop: 'sourceName',
    width: 150,
    value: 'dsp_place_id'
  },
  platName: {
    name: '广告平台',
    prop: 'platName',
    width: 150,
    value: 'plat_id'
  },
  osName: {
    name: '操作系统',
    prop: 'osName',
    width: 80,
    value: 'os'
  },
  packageName: {
    name: '产品包名',
    prop: 'packageName',
    width: 150,
    value: 'app_bundle'
  },
  productName: {
    name: '推广产品',
    prop: 'productName',
    width: 150,
    value: 'app_name'
  },
  type: {
    name: '素材类型',
    prop: 'type',
    width: 80,
    value: 'type'
  },
  trafficBundle: {
    name: '流量包名',
    prop: 'trafficBundle',
    width: 180,
    value: 'traffic_bundle'
  }
}

export const dimesionList = [
  {
    key: dimesionEnum.date.value,
    name: dimesionEnum.date.name,
    checked: false,
    prop: dimesionEnum.date.prop,
    width: dimesionEnum.date.width
  },
  {
    key: dimesionEnum.creativeUrl.value,
    name: dimesionEnum.creativeUrl.name,
    checked: false,
    prop: dimesionEnum.creativeUrl.prop,
    width: dimesionEnum.creativeUrl.width
  },
  {
    key: dimesionEnum.publisherName.value,
    name: dimesionEnum.publisherName.name,
    checked: false,
    prop: dimesionEnum.publisherName.prop,
    width: dimesionEnum.publisherName.width
  },
  {
    key: dimesionEnum.placeName.value,
    name: dimesionEnum.placeName.name,
    checked: false,
    prop: dimesionEnum.placeName.prop,
    width: dimesionEnum.placeName.width
  },
  {
    key: dimesionEnum.platName.value,
    name: dimesionEnum.platName.name,
    checked: false,
    prop: dimesionEnum.platName.prop,
    width: dimesionEnum.platName.width
  },
  {
    key: dimesionEnum.sourceName.value,
    name: dimesionEnum.sourceName.name,
    checked: false,
    prop: dimesionEnum.sourceName.prop,
    width: dimesionEnum.sourceName.width
  },
  {
    key: dimesionEnum.packageName.value,
    name: dimesionEnum.packageName.name,
    checked: false,
    prop: dimesionEnum.packageName.prop,
    width: dimesionEnum.packageName.width
  },
  {
    key: dimesionEnum.productName.value,
    name: dimesionEnum.productName.name,
    checked: false,
    prop: dimesionEnum.productName.prop,
    width: dimesionEnum.productName.width
  },
  {
    key: dimesionEnum.osName.value,
    name: dimesionEnum.osName.name,
    checked: false,
    prop: dimesionEnum.osName.prop,
    width: dimesionEnum.osName.width
  },
  {
    key: dimesionEnum.type.value,
    name: dimesionEnum.type.name,
    checked: false,
    prop: dimesionEnum.type.prop,
    width: dimesionEnum.type.width
  },
  {
    key: dimesionEnum.trafficBundle.value,
    name: dimesionEnum.trafficBundle.name,
    checked: false,
    prop: dimesionEnum.trafficBundle.prop,
    width: dimesionEnum.trafficBundle.width
  }
]
